<ion-segment @ionChange="segmentChanged($event)" :value="value">
  <ion-segment-button value="table">
    <ion-label>Table</ion-label>
  </ion-segment-button>
  <ion-segment-button value="grid">
    <ion-label>Grid</ion-label>
  </ion-segment-button>
  <ion-segment-button value="slides">
    <ion-label>Slides</ion-label>
  </ion-segment-button>
</ion-segment>
