<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        {{ message }}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col v-if="hasCancel">
        <ion-button color="secondary" expand="block" @click="cancel">
          Cancel
        </ion-button>
      </ion-col>
      <ion-col v-if="hasAction">
        <ion-button color="primary" expand="block" @click="action">
          OK
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
