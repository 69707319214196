import { popoverController } from '@ionic/vue';

import AppAlert from '@/components/base/AppAlert/AppAlert.vue';
import { AlertType } from '@/models/enums/AlertType.enum';

export default {
  async showAlert(
    type: AlertType,
    message: string,
    hasCancel?: boolean,
    hasAction?: boolean,
    actionCallback?: () => unknown,
    cancelCallback?: () => unknown
  ) {
    const alert = await popoverController.create({
      component: AppAlert,
      componentProps: {
        type,
        message,
        hasCancel,
        hasAction,
        actionCallback,
        cancelCallback
      }
    });

    return alert.present();
  }
};
