<ion-grid>
  <ion-row>
    <ion-col
      v-for="entry in data"
      :key="entry.id"
      size="12"
      :size-sm="cardSize"
    >
      <app-card
        :id="entry[cardData.id]"
        :title="entry[cardData.title]"
        :subtitle="prettyPrint(entry[cardData.subtitle])"
        :imgUrl="entry[cardData.imgUrl]"
        :text="prettyPrint(entry[cardData.text])"
        :date="entry[cardData.date]"
        :withActions="withActions"
        :withDetail="withDetail"
        @editEntry="editEntry"
        @deleteEntry="deleteEntry"
        @goToDetail="goToDetail"
      ></app-card>
    </ion-col>
  </ion-row>
</ion-grid>
