import { defineComponent, PropType } from 'vue';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  popoverController
} from '@ionic/vue';

import { AlertType } from '@/models/enums/AlertType.enum';

export default defineComponent({
  name: 'AppAlert',
  props: {
    type: String as PropType<AlertType>,
    message: {
      type: String,
      default: 'Careful!'
    },
    hasCancel: {
      type: Boolean,
      default: true
    },
    hasAction: {
      type: Boolean,
      default: false
    },
    actionCallback: {
      type: Function,
      required: false
    },
    cancelCallback: {
      type: Function,
      required: false
    }
  },
  methods: {
    cancel() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }
      popoverController.dismiss();
    },
    action() {
      if (this.actionCallback) {
        this.actionCallback();
      }
      popoverController.dismiss();
    }
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton
  }
});
