<ion-card>
  <ion-grid>
    <ion-row class="tHead ion-text-center ion-text-uppercase">
      <ion-col v-for="column in columns" :key="column">
        {{ column.label }}
      </ion-col>
      <ion-col size="1" v-if="isAuthenticated">Admin</ion-col>
    </ion-row>
    <ion-row class="tBody" v-for="entry in data" :key="entry.id">
      <ion-col class="tCell" v-for="column in columns" :key="column">
        {{ prettyPrint(entry[column.name], 25) }}
      </ion-col>
      <ion-col size="1" v-if="isAuthenticated">
        <ion-buttons>
          <ion-button @click="editEntry(entry.id)">
            <ion-icon :icon="pencil" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button @click="deleteEntry(entry.id)">
            <ion-icon :icon="trash" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
